import { DeleteUserApiResponse, User, UserListApiResponse, SaveUserApiResponse } from "../../../../common/types";
import { useFetchApi } from '../../../../common/hooks/useFetchApi'

/**
 * ユーザ関係
 */
export const useUser = () => {
    const { fetchApiWithToken } = useFetchApi();

    // ユーザ一覧取得
    const getUserList = async (): Promise<User[] | null> => {
        const result = await fetchApiWithToken<UserListApiResponse>({
            endpoint: '/api/web/get_user_list',
            method: 'GET',
            body: undefined
        });

        if (result === null || result.result_code !== '200') {
            // APIエラー
            return null;
        } else {
            return result.user_list;
        }
    }

    // ユーザ保存
    const saveUser = async (id: number | undefined, name: string, loginId: string, password: string | undefined): Promise<number | null> => {
        const result = await fetchApiWithToken<SaveUserApiResponse>({
            endpoint: '/api/web/save_user',
            method: 'POST',
            body: JSON.stringify({
                id: id === undefined || id === -1 ? null : id,
                name: name,
                login_id: loginId,
                password: password
            })
        });

        if (result === null || result.result_code !== '200') {
            // APIエラー
            return null;
        } else {
            return result.id
        }
    }

    //　ユーザ削除
    const deleteUser = async (id: number): Promise<boolean> => {
        const result = await fetchApiWithToken<DeleteUserApiResponse>({
            endpoint: '/api/web/delete_user',
            method: 'POST',
            body: JSON.stringify({
                id: id
            })
        });

        if (result === null || result.result_code !== '200') {
            // APIエラー
            return false;
        } else {
            return true
        }
    }

    return { getUserList, saveUser, deleteUser }
}