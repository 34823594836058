import { Backdrop, Box, Button, CircularProgress, CssBaseline, Toolbar } from "@mui/material"
import React from "react";
import { useNavigate } from "react-router-dom";
import { User, HeaderMenu } from '../../../../common/types'
import { AppBar, Drawer } from "../../../ui/Common";
import { useUser } from '../hooks/useUser';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons
} from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
  rows: GridRowsProp
}

const EditToolbar = (props: EditToolbarProps) => {
  const { setRows, setRowModesModel, rows } = props;

  const handleClick = () => {
    if (rows.find(row => row.id === -1) !== undefined) {
      return
    }

    const id = -1;
    setRows((oldRows) => [...oldRows, { id, name: '', password: '', login_id: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        新規追加
      </Button>
    </GridToolbarContainer>
  );
}

export const UserList = () => {
  const navigate = useNavigate();
  const [open] = React.useState(true);
  const [, setUsers] = React.useState<User[]>([])
  const { getUserList, deleteUser, saveUser } = useUser();
  const { t } = useTranslation();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchUserList = async () => {
      const userList = await getUserList();
      if (userList !== null) {
        setUsers(userList);
        setRows(userList.flatMap<GridRowsProp>(row => row === null ? [] : [{ id: row.id, name: row.name, password: row.password, login_id: row.login_id, isNew: false }]))
      }
    }
    fetchUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHeaderMenu = (menu: HeaderMenu) => {
    if (menu === 'user') {
      return;
    }
    switch (menu) {
      case 'home':
        navigate('/Home');
        break;
      case 'record':
        navigate('/Video');
        break;
      case 'streaming':
        navigate('/LiveStreaming');
        break;
      case 'device':
        navigate('/DeviceList');
    }
  }

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    const target = rows.find(row => row.id === id);
    if (target === undefined) {
      return;
    }

    if (rows.length === 1) {
      alert("ユーザは最低1人は必要です。");
      return;
    }

    if (window.confirm(t('ユーザ「{{ name, string }}」を削除します。よろしいですか。', { name: target!!.name }))) {
      setIsLoading(true);
      const result = await deleteUser(target!!.id);
      setIsLoading(false);

      if (result) {
        // 成功
        setRows(rows.filter(row => row.id !== id));
      } else {
        alert(t('ユーザ削除に失敗しました。再度削除してください。'));
      }
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find(row => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter(row => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    console.log(newRow);
    if (newRow.id === -1) {
      // 必須チェック
      if (!newRow.name || !newRow.login_id || !newRow.password) {
        alert(t("ユーザ名、ログインIDおよびパスワードは必須項目です。"));
        return;
      }
    } else {
      if (newRow.password) {
        if (!window.confirm(t('パスワードを変更します。よろしいですか。\n※変更しない場合はパスワード欄を空欄にしてください。'))) {
          return;
        }
      }

      if (!newRow.name || !newRow.login_id) {
        alert(t("ユーザ名およびログインIDは必須項目です。"));
        return;
      }
    }

    // 保存
    setIsLoading(true);
    const result = await saveUser(newRow.id, newRow.name, newRow.login_id, newRow.password);
    setIsLoading(false);
    if (result !== null) {
      // 成功
      if (newRow.id === -1) {
        newRow.id = result;
        const updatedRow = { ...newRow, isNew: false, password: '' };
        setRows(rows.map(row => (row.id === -1 ? updatedRow : row)));
        return updatedRow;
      } else {
        const updatedRow = { ...newRow, isNew: false, password: '' };
        setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      }
    } else {
      alert(t('保存に失敗しました。再度保存してください。'));
      setRowModesModel({ ...rowModesModel, [newRow.id]: { mode: GridRowModes.Edit } });
      return undefined;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: t('ユーザ名'), flex: 1, editable: true },
    { field: 'login_id', headerName: t('ログインID'), flex: 1, editable: true },
    { field: 'password', headerName: t('パスワード'), flex: 1, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('操作'),
      width: 120,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label={t('保存')}
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label={t('キャンセル')}
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={t('編集')}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label={t('削除')}
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar open={open} handleHeaderMenu={handleHeaderMenu} selectedHeaderMenu={'user'} />
        <Drawer open={open} menuListItem={undefined} />
        <Box
            component="main"
            sx={{
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            }}
        >
          <Toolbar />
          <DataGrid
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel, rows },
            }}
            sx={{
              m: 2,
              height: '90vh',
              backgroundColor: '#FFFFFF'
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
          />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
    </Box>
  )
}