import './App.css';
import { SignIn } from './components/features/SignIn/container';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { LiveStreaming } from './components/features/LiveStreaming/container';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ja from './assets/locales/ja.json';
import { Video } from './components/features/Video/container';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ja';
import { DeviceList } from './components/features/DeviceList/container';
import { UserList } from './components/features/UserList/container';

// 翻訳
const getLanguage = () => i18n.language || window.localStorage.i18nextLng
i18n.use(initReactI18next).init({
  resources: {
    ja: { translation: ja }
  },
  lng: getLanguage(),
  fallbackLng: 'ja'
})

// ルータ作成
const router = createBrowserRouter([
  {
    path: "/",
    element: <SignIn />
  },
  {
    path: "/Home",
    element: <SignIn />
  },
  {
    path: "/LiveStreaming",
    element: <LiveStreaming />
  },
  {
    path: "/Video",
    element: <Video />
  },
  {
    path: "/DeviceList",
    element: <DeviceList />
  },
  {
    path: "/UserList",
    element: <UserList />
  }
])

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ja'}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </div>
  );
}

export default App;
