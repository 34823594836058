import { useCallback } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { authAtom, isLoggedIn, unLoggedIn } from '../../store/atoms/auth';

/**
 * ログイン状態取得hooks
 * @returns 
 */
export const useAuth = () => {
  return useAtomValue(authAtom);
};

/**
 * ログイン状態変更hooks
 * @returns 
 */
export const useAuthMutators = () => {
  const setAuth = useSetAtom(authAtom);
  
  /** ログアウト設定 */
  const clearAuth = useCallback(() => {
    setAuth(RESET);
  }, [setAuth]);

  /** ログイン設定 */
  const login = useCallback(() => {
    setAuth(isLoggedIn);
  }, [setAuth]);

  /** ログインエラー設定 */
  const loginError = useCallback(() => {
    setAuth(unLoggedIn);
  }, [setAuth]);
  
  return { clearAuth, login, loginError };
};