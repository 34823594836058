import { DeleteDeviceApiResponse, Device, DeviceListApiResponse, SaveDeviceApiResponse } from "../types";
import { useFetchApi } from "./useFetchApi";

/**
 * デバイス関係
 */
export const useDevice = () => {
    const { fetchApiWithToken } = useFetchApi();

    // デバイス一覧取得
    const getDeviceList = async (): Promise<Device[] | null> => {
        const result = await fetchApiWithToken<DeviceListApiResponse>({
            endpoint: '/api/web/get_device_list',
            method: 'GET',
            body: undefined
        });

        if (result === null || result.result_code !== '200') {
            // APIエラー
            return null;
        } else {
            const list: Device[] = [];
            for (var i = 0; i < result.device_list.length; i++) {
                const device = result.device_list[i];
                device.order = i;
                list.push(device);
            }
            return list;
        }
    }

    // デバイス保存
    const saveDevice = async (id: number | undefined, device_id: string, name: string, group: number | null): Promise<number | null> => {
        const result = await fetchApiWithToken<SaveDeviceApiResponse>({
            endpoint: '/api/web/save_device',
            method: 'POST',
            body: JSON.stringify({
                id: id === undefined || id === -1 ? null : id,
                device_id: device_id,
                name: name,
                group: group
            })
        });

        if (result === null || result.result_code !== '200') {
            // APIエラー
            return null;
        } else {
            return result.id
        }
    }

    const deleteDevice = async (id: number): Promise<boolean> => {
        const result = await fetchApiWithToken<DeleteDeviceApiResponse>({
            endpoint: '/api/web/delete_device',
            method: 'POST',
            body: JSON.stringify({
                id: id
            })
        });

        if (result === null || result.result_code !== '200') {
            // APIエラー
            return false;
        } else {
            return true
        }
    }

    return { getDeviceList, saveDevice, deleteDevice }
}