import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import { useSignIn } from '../hooks/useSignIn';
import { useTranslation } from 'react-i18next';

/**
 * サインイン画面生成
 * @returns サインイン画面コンポーネント
 */
export const SignIn = () => {
  const navigate = useNavigate();
  const [inputError, setInputError] = React.useState(false);
  const authenticate = useSignIn()
  const { t } = useTranslation();

  // ログインボタン押下処理
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInputError(false);
    const data = new FormData(event.currentTarget);

    const id = data.get('id');
    const pass = data.get('password');

    if (id === null || pass === null) {
      setInputError(true);
      return;
    }

    // ログイン認証
    const authResult = await authenticate(id.toString(), pass.toString());
    if (authResult) {
      navigate("/LiveStreaming");
    } else {
      setInputError(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <Typography component="h1" variant="h5">
          {t('サインイン')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="id"
            label="ID"
            name="id"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          {inputError ? <Alert severity="error">{t('IDまたはパスワードに誤りがあります。')}</Alert> : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('サインイン')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                {t('パスワードを忘れた方はこちら')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}