import Grid from "@mui/material/Grid"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Tab, Tabs, TextField, Typography } from "@mui/material";
import { ChangeEvent, RefObject, useEffect, useRef, useState } from "react";
import { Device } from "../../../../common/types";
import { createSignalingChannel, createMaster, startViewer } from "../presenter";
import { useTranslation } from "react-i18next";
import { useStreaming } from "../hooks/useStreaming";
import { useAtom } from "jotai";
import { accessKeyIdAtom, secretAccessKeyAtom } from "../../../../store/atoms/aws";
import { deviceSettingAtom } from "../../../../store/atoms/device";
import { ButtonProps } from "@mui/base";

/** タブ */
const TabType = {
    Fisheye: 0,
    Panorama: 1,
    Cut: 2,
    Movie: 3
} as const

/** 切り出し位置 */
const useDegreeRef = (): {
    degree: number;
    degreeRef: RefObject<number>;
    setDegree: (state: number) => void;
  } => {
    const degreeRef = useRef(0);
    const [degree, setDegree] = useState(0);
  
    const setter = (state: number) => {
      if(state<0) state=0;
      degreeRef.current = state;
      setDegree(state);
    };
  
    return { degree, degreeRef, setDegree: setter };
  };

/** 切り出し位置 */
const useIsFlipRef = (): {
    isFlip: boolean;
    isFlipRef: RefObject<boolean>;
    setIsFlip: (state: boolean) => void;
  } => {
    const isFlipRef = useRef(false);
    const [isFlip, setIsFlip] = useState(false);
  
    const setter = (state: boolean) => {
        isFlipRef.current = state;
        setIsFlip(state);
    };
  
    return { isFlip, isFlipRef, setIsFlip: setter };
  };

/** シングル */
export const SingleVideo = ({device} : {device: Device | undefined}) => {
    const { startStreaming } = useStreaming();
    const [isRunning, setIsRunning] = useState(false);
    const [tab, setTab] = useState<number>(TabType.Fisheye);
    const { degree, setDegree, degreeRef } = useDegreeRef();
    const { isFlip, setIsFlip, isFlipRef } = useIsFlipRef();
    const { t } = useTranslation();
    const [accessKeyId, ] = useAtom(accessKeyIdAtom);
    const [secretAccessKey, ] = useAtom(secretAccessKeyAtom);
    const [deviceSetting, setDeviceSetting] = useAtom(deviceSettingAtom);

    const handleClickStartStreaming = async (target: Device) => {
        // チャンネル確認・作成
        await createSignalingChannel(accessKeyId, secretAccessKey, target.device_id);

        // master作成
        const { master, iceServer } = await createMaster(accessKeyId, secretAccessKey, {
            channelName: target.device_id,
            natTraversal: 'STUN/TURN',
            widescreen: false,
            useTrickleICE: true,
        });

        const result = await startStreaming(target.device_id, master, iceServer);
        if (result) {
            setIsRunning(true);
            setTimeout(async function() {
                // viewer接続
                await startViewer(accessKeyId, secretAccessKey, {
                    channelName: target.device_id,
                    natTraversal: 'STUN/TURN',
                    widescreen: false,
                    sendVideo: false,
                    sendAudio: false,
                    useTrickleICE: true,
                    video: document.getElementById('video-' + target.device_id),
                    fisheyeCanvas: document.getElementById('fisheye-' + target.device_id),
                    panoramaCanvas: document.getElementById('panorama-' + target.device_id),
                    upperCanvas: document.getElementById('panorama-upper-' + target.device_id),
                    lowerCanvas: document.getElementById('panorama-lower-' + target.device_id),
                    degree: degreeRef,
                    isFlip: isFlipRef
                });
                
            }, 3000);
        } else {
            // エラー
            alert(t('ストリーミング開始処理に失敗しました。再度ストリーミング開始してください。'));
        }
    }

    // タブ変更時
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
        if (deviceSetting.some(x => x.device_id === device?.device_id)) {
            setDeviceSetting(deviceSetting.map(x => x.device_id === device?.device_id ? {...x, openTab: newValue } : x));
        }
    };

    // 位置変更時
    const handleDegreeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const deg = event.target.value;
        try {
            const intDeg = Number.isNaN(Number(deg)) ? 0 : Number(deg);
            setDegree(intDeg);

            if (deviceSetting.some(x => x.device_id === device?.device_id)) {
                setDeviceSetting(deviceSetting.map(x => x.device_id === device?.device_id ? {...x, degree: intDeg } : x));
            }
        } catch {
            alert('切り出し位置は整数値で入力してください。')
        }
    }

    // 180度回転チェックボック変更時
    const handleOnIsFlipChange = (event: ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setIsFlip(checked)
        if (deviceSetting.some(x => x.device_id === device?.device_id)) {
            setDeviceSetting(deviceSetting.map(x => x.device_id === device?.device_id ? {...x, isFlip: checked } : x));
        }
    }

    // 開始コンポーネント
    const StartButton = (props: ButtonProps) => (
        <Button variant="contained" startIcon={<PlayArrowIcon />} onClick={() => handleClickStartStreaming(device!!)}>
            {t('ストリーミング開始')}
        </Button>
    )

    // 切替時ストリーミング停止
    useEffect(() => {
        setIsRunning(false)
        setDegree(0);
        setTab(TabType.Fisheye);
        setIsFlip(false);

        // 設定チェック        
        const targetDeviceSetting = deviceSetting.find(x => x.device_id === device?.device_id);
        if (targetDeviceSetting) {
            setDegree(targetDeviceSetting.degree);
            setTab(targetDeviceSetting.openTab);
            setIsFlip(targetDeviceSetting.isFlip);
        } else if (device) {
            setDeviceSetting([...deviceSetting, { device_id: device!.device_id, openTab: TabType.Fisheye, degree: 0, isFlip: false }])
        }
        // eslint-disable-next-line
    }, [device])

    return (
        <Container maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Grid container alignItems={'stretch'}>
                <Container maxWidth={false} sx={{ my: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            margin="normal"
                            name="degree"
                            label={t("切り出し位置")}
                            type="number"
                            id="deg"
                            value={degree}
                            sx={{
                                width: "300px"
                            }}
                            onChange={handleDegreeChange}
                        />
                    </Box>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={handleTabChange}>
                            <Tab label={t("球面")} id={`tab-${TabType.Fisheye}`} aria-controls={`tabpanel-${TabType.Fisheye}`} />
                            <Tab label={t("パノラマ")} id={`tab-${TabType.Panorama}`} aria-controls={`tabpanel-${TabType.Panorama}`} />
                            <Tab label={t("切り出し")} id={`tab-${TabType.Cut}`} aria-controls={`tabpanel-${TabType.Cut}`} />
                            <Tab label={t("元動画")} id={`tab-${TabType.Movie}`} aria-controls={`tabpanel-${TabType.Movie}`} />
                        </Tabs>
                    </Box>
                    <div role="tabpanel" hidden={TabType.Fisheye !== tab} id={`tabpanel-${TabType.Fisheye}`} aria-labelledby={`tab-${TabType.Fisheye}`} style={{height: "70vh"}}>
                        <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.3);', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1, height: '100%' }}>
                            { isRunning ? <canvas id={'fisheye-' + device?.device_id} style={{width: '100%', maxWidth: '720px'}}></canvas> : <StartButton /> }
                        </Box>
                    </div>
                    <div role="tabpanel" hidden={TabType.Panorama !== tab} id={`tabpanel-${TabType.Panorama}`} aria-labelledby={`tab-${TabType.Panorama}`} style={{height: "70vh"}}>
                        <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.3);', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1, height: '100%'  }}>
                            { isRunning ? <canvas id={'panorama-' + device?.device_id} style={{width: '100%', maxWidth: '1440px'}}></canvas> : <StartButton /> }
                        </Box>
                    </div>
                    <div role="tabpanel" hidden={TabType.Cut !== tab} id={`tabpanel-${TabType.Cut}`} aria-labelledby={`tab-${TabType.Cut}`} style={{height: "70vh"}}>
                        <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.3);', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1, height: '100%' }}>
                            { isRunning ?
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            align='left'
                                            color="inherit"
                                            noWrap
                                            sx={{
                                                width: "720px",
                                                margin: "0 auto"
                                            }}
                                        >
                                            {`${(degree%360)} ~ ${(degree + 180)%360}`}
                                        </Typography>
                                        <canvas id={'panorama-upper-' + device?.device_id} style={{width: '100%', maxWidth: '720px'}}></canvas>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            align='left'
                                            color="inherit"
                                            noWrap
                                            sx={{
                                                width: "720px",
                                                margin: "0 auto"
                                            }}
                                        >
                                            {`${(degree + 180)%360} ~ ${(degree + 360)%360}`}
                                        </Typography>
                                        <canvas id={'panorama-lower-' + device?.device_id} style={{width: '100%', maxWidth: '720px'}}></canvas>
                                    </Grid>
                                </Grid>
                                : 
                                <StartButton />
                            }
                        </Box>
                    </div>
                    <div role="tabpanel" hidden={TabType.Movie !== tab} id={`tabpanel-${TabType.Movie}`} aria-labelledby={`tab-${TabType.Movie}`} style={{height: "70vh"}}>
                        <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.3);', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1, height: '100%' }}>
                            { isRunning ? <video id={'video-' + device?.device_id} style={{width: '100%', height: '100%'}} controls></video> : <StartButton /> }
                        </Box>
                    </div>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormGroup sx={{ ml: 2 }}>
                            <FormControlLabel control={<Checkbox onChange={handleOnIsFlipChange} checked={isFlip} />} label={t("180度回転")} />
                        </FormGroup>
                        <Typography
                            align='right'
                            color="inherit"
                            noWrap
                        >
                            {device?.name}
                        </Typography>
                    </Box>
                </Container>
            </Grid>
        </Container>
    );
}