import cv from 'opencv-ts';
import { RefObject } from 'react';

const MAP_RANGE = 720;
const SRC_CENTER_X = MAP_RANGE / 2;
const SRC_CENTER_Y = MAP_RANGE / 2;
const SRC_R1 = MAP_RANGE / 2;
const SRC_R2 = 0;

/** パノラマ化ユーティリティ */
export const PanoramaUtil = ({video, fishEyeCanvas, panoramaCanvas, upperCanvas, lowerCanvas, degree, isFlip }: {
        video: HTMLVideoElement,
        fishEyeCanvas: HTMLCanvasElement,
        panoramaCanvas: HTMLCanvasElement,
        upperCanvas: HTMLCanvasElement,
        lowerCanvas: HTMLCanvasElement,
        degree: RefObject<number | undefined>,
        isFlip: RefObject<boolean>
    }) => {
        let startAngle = (degree.current ?? 0) * (2 * Math.PI / 360);
        let isFlipImage = isFlip.current ?? false;
        let isReading = false;
        
        const xmap = new cv.Mat(MAP_RANGE / 2, MAP_RANGE * 2, cv.CV_32F);
        const ymap = new cv.Mat(MAP_RANGE / 2, MAP_RANGE * 2, cv.CV_32F);

        const xmapUpper = new cv.Mat(MAP_RANGE / 2, MAP_RANGE, cv.CV_32F);
        const ymapUpper = new cv.Mat(MAP_RANGE / 2, MAP_RANGE, cv.CV_32F);
        const xmapLower = new cv.Mat(MAP_RANGE / 2, MAP_RANGE, cv.CV_32F);
        const ymapLower = new cv.Mat(MAP_RANGE / 2, MAP_RANGE, cv.CV_32F);

        // const panoSize = new cv.Size(MAP_RANGE * 2, MAP_RANGE / 2);
        // const panoCenterX = panoSize.width / 2;
        // const panoCenterY = panoSize.height / 2;
        // const rotateMatrix = cv.getRotationMatrix2D(new cv.Point(panoCenterX, panoCenterY), 180, 1.0);

        // const halfPanoSize = new cv.Size(MAP_RANGE, MAP_RANGE / 2);
        // const halfPanoCenterX = halfPanoSize.width / 2;
        // const halfPanoCenterY = halfPanoSize.height / 2;
        // const halfRotateMatrix = cv.getRotationMatrix2D(new cv.Point(halfPanoCenterX, halfPanoCenterY), 180, 1.0);
        
        // パノラマ化
        const videoToPanorama = () => {
            if (isReading || video.paused || video.ended) return;
            isReading = true;
            const angle = (degree.current ?? 0) * (2 * Math.PI / 360);
            if (angle !== startAngle || isFlipImage !== (isFlip.current ?? false)) {
                console.log("angle");
                console.log(angle != startAngle);
                console.log("flip");
                console.log(isFlipImage !== (isFlip.current ?? false));
                startAngle = angle;
                isFlipImage = isFlip.current ?? false;
                createMap();
            }

            const rcTrimFrame = new cv.Rect(310, 0, 720, 720);
//            const rcTrimFrame = new cv.Rect(850, 0, 2160, 2160);
//            const rcTrimFrame = new cv.Rect(420, 0, 1080, 1080);

            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext('2d');

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            try {
                ctx!.drawImage(video, 0, 0);
                const src = cv.imread(canvas);
                const dst1 = src.roi(rcTrimFrame);
                const dst2 = new cv.Mat();
                const dstUpper = new cv.Mat();
                const dstLower = new cv.Mat();

                cv.remap(dst1, dst2, xmap, ymap, cv.INTER_CUBIC);
                cv.remap(dst1, dstUpper, xmapUpper, ymapUpper, cv.INTER_CUBIC);
                cv.remap(dst1, dstLower, xmapLower, ymapLower, cv.INTER_CUBIC);

                if (fishEyeCanvas) cv.imshow(fishEyeCanvas, dst1);
                if (panoramaCanvas) cv.imshow(panoramaCanvas, dst2);
                if (upperCanvas) cv.imshow(upperCanvas, dstUpper);
                if (lowerCanvas) cv.imshow(lowerCanvas, dstLower);

                src.delete();
                dst1.delete();
                dst2.delete();
                dstUpper.delete();
                dstLower.delete();
            } catch (e) {
                console.log(e);
            } finally {
                isReading = false;
            }
    }

    // map作成
    const createMap = () => {
        let theta = 0;
        let thetaU = 0;
        let thetaL = Math.PI;
        let l = 0;
        let ymax = SRC_R1 - SRC_R2;

        for (let i = 0; i < ymax; i++) {
            for (let j = 0; j < MAP_RANGE * 2; j++) {
                theta = startAngle + Math.PI - (Math.PI / MAP_RANGE) * j;
                l = SRC_R1 - i;
                xmap.floatPtr(i, j)[0] = l * Math.cos(Math.PI - theta) + SRC_CENTER_X;
                ymap.floatPtr(i, j)[0] = l * Math.sin(Math.PI - theta) + SRC_CENTER_Y;
            }
        }

        for (let i = 0; i < ymax; i++) {
            for (let j = 0; j < MAP_RANGE; j++) {
                thetaU = startAngle + Math.PI - (Math.PI / MAP_RANGE) * j;
                thetaL = startAngle + Math.PI + Math.PI - (Math.PI / MAP_RANGE) * j;
                l = SRC_R1 - i;
                xmapUpper.floatPtr(i, j)[0] = l * Math.cos(Math.PI - thetaU) + SRC_CENTER_X;
                ymapUpper.floatPtr(i, j)[0] = l * Math.sin(Math.PI - thetaU) + SRC_CENTER_Y;
                xmapLower.floatPtr(i, j)[0] = l * Math.cos(Math.PI - thetaL) + SRC_CENTER_X;
                ymapLower.floatPtr(i, j)[0] = l * Math.sin(Math.PI - thetaL) + SRC_CENTER_Y;
            }
        }

        if (isFlipImage) {
            cv.flip( xmap, xmap, -1 );
            cv.flip( ymap, ymap, -1 );
            cv.flip( xmapUpper, xmapUpper, -1 );
            cv.flip( ymapUpper, ymapUpper, -1 );
            cv.flip( xmapLower, xmapLower, -1 );
            cv.flip( ymapLower, ymapLower, -1 );
        }

        // if (isFlipImage) {
        //     console.log(isFlipImage);
        //     cv.warpAffine(xmap, xmap, rotateMatrix, panoSize, cv.INTER_CUBIC, cv.BORDER_CONSTANT, new cv.Scalar());
        //     cv.warpAffine(ymap, ymap, rotateMatrix, panoSize, cv.INTER_CUBIC, cv.BORDER_CONSTANT, new cv.Scalar());

        //     cv.warpAffine(xmapUpper, xmapUpper, halfRotateMatrix, halfPanoSize, cv.INTER_CUBIC, cv.BORDER_CONSTANT, new cv.Scalar());
        //     cv.warpAffine(ymapUpper, ymapUpper, halfRotateMatrix, halfPanoSize, cv.INTER_CUBIC, cv.BORDER_CONSTANT, new cv.Scalar());
        //     cv.warpAffine(xmapLower, xmapLower, halfRotateMatrix, halfPanoSize, cv.INTER_CUBIC, cv.BORDER_CONSTANT, new cv.Scalar());
        //     cv.warpAffine(ymapLower, ymapLower, halfRotateMatrix, halfPanoSize, cv.INTER_CUBIC, cv.BORDER_CONSTANT, new cv.Scalar());
        // }
    }
    createMap();

    return { videoToPanorama }
}