const REGION =  "ap-northeast-1";

export const awsConfig = {
    region: REGION,
};


/** 基底URL */
//export const baseUrl = 'http://localhost:8000';
//export const baseUrl = 'https://ni2.knowledgebeans-cloud.com';
export const baseUrl = 'https://ni-fisheye-api.nandis.jp';
//export const baseUrl = 'http://3.112.132.216:8000';
