import { useAtom } from "jotai";
import { authTokenAtom, authTokenTypeAtom } from "../../store/atoms/auth";
import { baseUrl } from "../constants";

/**
 * API通信hooks
 * @returns 
 */
export const useFetchApi = () => {
    const [token] = useAtom(authTokenAtom);
    const [tokenType] = useAtom(authTokenTypeAtom)

    /**
     * API呼び出し
     * @param param0 
     * @returns 
     */
    const fetchApi = async <T>({ endpoint, method, body }:
        {
            endpoint: string,
            method: 'POST' | 'GET',
            body: string | undefined
        }): Promise<T | null> => {

            const request = {
                method: method,
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors' as RequestMode
            }

            const response = await fetch(
                baseUrl + endpoint, 
                body === undefined ? request : { ...request, body: body }
            );

            if (response.status === 200) {
                const res: T = await response.json();
                return res;
            } else {
                return null;
            }
        }

    /**
     * API呼び出し（トークン付与）
     * @param param0 
     * @returns 
     */
    const fetchApiWithToken = async <T>({ endpoint, method, body }:
        {
            endpoint: string,
            method: 'GET' | 'POST',
            body: string | undefined
        }): Promise<T | null>  => {

            const request = {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  tokenType + ' ' + token,
                },
                mode: 'cors' as RequestMode
            }
            
            const response = await fetch(
                baseUrl + endpoint,
                body === undefined ? request : { ...request, body: body }
            );

            if (response.status === 200) {
                const res: T = await response.json();
                return res;
            } else {
                return null;
            }
        }

    return { fetchApi, fetchApiWithToken }
}