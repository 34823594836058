import { useFetchApi } from "../../../../common/hooks/useFetchApi";
import { ApiResponse, GetUploadFinishedResponse } from "../../../../common/types";

/**
 * ビデオhooks
 * @returns 
 */
export const useVideo = () => {
    const { fetchApiWithToken } = useFetchApi();

    // アップロードタスク
    const videoUpload = async (deviceId: string, start: string, end: string): Promise<boolean> => {
        const result = await fetchApiWithToken<ApiResponse>({
            endpoint: '/api/web/add_video_upload_task',
            method: 'POST',
            body: JSON.stringify({
                device_id: deviceId,
                start: start,
                end: end
            })
        });

        if (result === null || result.result_code !== '200') {
            // APIエラー
            return false;
        } else {
            return true;
        }
    }

    // ステータスチェック
    const getUploadFinished = async(deviceId: string): Promise<boolean | undefined> => {
        const result = await fetchApiWithToken<GetUploadFinishedResponse>({
            endpoint: '/api/web/check_task_finished',
            method: 'POST',
            body: JSON.stringify({
                device_id: deviceId
            })
        });

        if (result === null || result.result_code !== '200') {
            // APIエラー
            return false;
        } else {
            return result.is_finished;
        }
    }

    // ビデオ削除
    const deleteVideo = async(videoId: number): Promise<boolean> => {
        const result = await fetchApiWithToken<ApiResponse>({
            endpoint: '/api/web/delete_video',
            method: 'POST',
            body: JSON.stringify({
                video_id: videoId.toString()
            })
        });

        if (result === null || result.result_code !== '200') {
            // APIエラー
            return false;
        } else {
            return true;
        }
    }

    return { videoUpload, getUploadFinished, deleteVideo }
  };