import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { MultiVideo } from './MultiVideo';
import FolderIcon from '@mui/icons-material/Folder';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SingleVideo } from './SingleVideo';
import { useDevice } from '../../../../common/hooks/useDevice';
import { Device, HeaderMenu } from '../../../../common/types';
import { AppBar, Drawer } from '../../../ui/Common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tree } from '@minoru/react-dnd-treeview';
import styles from "./index.module.css";
import { Typography } from '@mui/material';

/** メニュー */
type MenuItem = {
  id: number,
  parent: number,
  droppable: boolean,
  text: string,
  data: {
    device_id: string
  }
}

/** ノード */
const CustomNode = (props: any) => {
  const indent = props.depth * 24;
  const handleSelect = () => props.onSelect(props.node.id);

  return (
    <div
    className={`tree-node ${styles.root} ${
      props.isSelected ? styles.isSelected : ""
    }`}
      style={{ paddingInlineStart: indent }}
      onClick={handleSelect}
    >
      <div
        className={`${styles.expandIconWrapper} ${
          props.isOpen ? styles.isOpen : ""
        }`}
      >
        {props.node.droppable && (
          <div onClick={(e) => {
            e.stopPropagation();
            props.onToggle(props.node.id);
          }}>
            <ArrowRightIcon />
          </div>
        )}
      </div>
      {props.node.id === 0 && (
        <FolderIcon />
      )}
      {props.node.id !== 0 && (
        <VideoCameraBackIcon />
      )}
      <div className={styles.labelGridItem}>
        <Typography variant="body2">{`${props.node.text}`}</Typography>
      </div>
    </div>
  );
};

/**
 * メニュー作成
 * @param deviceList 
 * @param onNodeSelect 
 * @returns 
 */
const MenuListItem = (deviceList: MenuItem[], onChangetDeviceList: ((items: MenuItem[]) => void), onNodeSelect: (id: number) => void) => {
  const [selectedNode, setSelectedNode] = React.useState<number | null>(null);
  const handleSelect = (id: number) => {
    setSelectedNode(id);
    onNodeSelect(id);
  }
  return (
    <Tree<any>
      tree={deviceList}
      rootId={-1}
      onDrop={ (newTreeData, ) => { onChangetDeviceList(newTreeData as MenuItem[]) }}
      render={(node, { depth, isOpen, onToggle }) => (
        <CustomNode
                node={node}
                depth={depth}
                isOpen={isOpen}
                isSelected={node.id === selectedNode}
                onToggle={onToggle}
                onSelect={handleSelect}
              />
      )}
      initialOpen={[0]}
      sort={false}
      insertDroppableFirst={false}
      canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
        if (dragSource?.parent === dropTargetId) {
          return true;
        }
      }}
      dropTargetOffset={5}
      classes={{
        root: styles.treeRoot,
        draggingSource: styles.draggingSource,
        placeholder: styles.placeholderContainer
      }}
      />
  )
};

/**
 * ライブストリーミング画面
 * @returns 
 */
export const LiveStreaming = () => {
  const navigate = useNavigate();
  const [open] = React.useState(true);
  const [devices, setDevicess] = React.useState<Device[]>([])
  const [menuItem, setMenuItem] = React.useState<MenuItem[]>([])
  const [selectDevice, setSelectDevice] = React.useState<Device | undefined>(undefined);
  const [isMulti, setIsMulti] = React.useState(true);
  const { getDeviceList } = useDevice();
  const { t } = useTranslation();

  React.useEffect(() => {
    const fetchDeviceList = async () => {
      const deviceList = await getDeviceList();
      if (deviceList !== null) {
        deviceList.sort((first, second) => {
          if (first.order > second.order) {
            return 1;
          } else if (first.order < second.order) {
            return -1;
          } else {
            return 0;
          }
         })
        setDevicess(deviceList);

        const list = [{ id: 0, parent: -1, droppable: true, text: t('全国のカメラ', { current: deviceList.length, total: deviceList.length }), data: { device_id: "" } }];
        for (var i = 0; i < deviceList.length; i++) {
          const device = deviceList[i];
          list.push({
            id: device.id,
            parent: 0,
            droppable: false,
            text: device.name,
            data: {
              device_id: device.device_id
            }
          })
        }
        setMenuItem(list);
      }
    }
    fetchDeviceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ヘッダ選択
  const handleHeaderMenu = (menu: HeaderMenu) => {
    if (menu === 'streaming') {
      return;
    }
    switch (menu) {
      case 'home':
        navigate('/Home');
        break;
      case 'record':
        navigate('/Video');
        break;
      case 'device':
        navigate('/DeviceList');
        break;
      case 'user':
        navigate('/UserList');
        break;
    }
  }

  // ノード選択
  const onNodeSelect = (id: number) => {
    if (id === 0) {
      setIsMulti(true);
    } else {
      const targetDevice = devices.find(row => row.id === id);
      if (targetDevice !== undefined) {
        setIsMulti(false);
        setSelectDevice(targetDevice);
      }
    }
  };

  // ノード入れ替え
  const onChangetDeviceList = (item: MenuItem[]) => {
    const order = [];
    for (var i = 0; i < item.length; i++) {
      if (item[i].id === 0) {
        continue;
      }

      order.push({
        id: item[i].id,
        order: i
      })
    }

    const list: Device[] = [];
    for (var j = 0; j < devices.length; j++) {
      const device = devices[j];
      device.order = order.find(row => row.id === device.id)?.order ?? 0
      list.push(device);
    }
    list.sort((first, second) => {
      if (first.order > second.order) {
        return 1;
      } else if (first.order < second.order) {
        return -1;
      } else {
        return 0;
      }
     })
    setDevicess(list);
    setMenuItem(item);
  }

  return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar open={open} handleHeaderMenu={handleHeaderMenu} selectedHeaderMenu={'streaming'} />
        <Drawer open={open} menuListItem={MenuListItem(menuItem, onChangetDeviceList, onNodeSelect)} />
        <Box
            component="main"
            sx={{
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
              {
                isMulti ? 
                <MultiVideo devices={devices}  />
                :
                <SingleVideo device={selectDevice} />
              }
            </Container>
        </Box>
    </Box>
  );
}