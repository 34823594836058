import * as React from 'react';
import { CssBaseline, Box, Toolbar, Container, IconButton, CircularProgress, Backdrop } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import { TreeView } from '@mui/x-tree-view';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { StyledTreeItem } from '../../../ui/CustomTree';
import { Device, HeaderMenu } from '../../../../common/types';
import { AppBar, Drawer } from '../../../ui/Common';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useDevice } from '../../../../common/hooks/useDevice';
import { useVideo } from '../hooks/useVideo';
import { baseUrl } from "../../../../common/constants";

/**
 * メニュー作成
 * @param deviceList 
 * @param onNodeSelect 
 * @returns 
 */
const MenuListItem = (deviceList: Device[], onNodeSelect: (event: React.SyntheticEvent, nodeIds: string[]) => void) => {
  const { t } = useTranslation();

  // ツリー表示
  const treeItems = () => {
    const list = []
    for (var i = 0; i < deviceList.length; i++) {
      const device = deviceList[i];
      list.push(
        <StyledTreeItem
          nodeId={device.device_id}
          labelText={device.name}
          labelIcon={VideoCameraBackIcon}
          color="#1a73e8"
          bgColor="#e8f0fe"
          colorForDarkMode="#B8E7FB"
          bgColorForDarkMode="#071318"
          key={device.device_id}
        />
      )
    }
    return list;
  }

  return (
    <TreeView
      aria-label='menu'
      defaultExpanded={['1']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      onNodeSelect={onNodeSelect}
      sx={{ flexGrow: 1, overflowY: 'auto' }}
    >
      <StyledTreeItem nodeId='1' labelText={t('全国のカメラ', { current: deviceList.length, total: deviceList.length })} labelIcon={FolderIcon}>
        {treeItems()}
      </StyledTreeItem>
    </TreeView>
  )
};

/**
 * 録画画面
 * @returns 
 */
export const Video = () => {
  const navigate = useNavigate();
  const [open] = React.useState(true);
  const [devices, setDevicess] = React.useState<Device[]>([])
  const [selectDevice, setSelectDevice] = React.useState<Device | undefined>(undefined);
  const { getDeviceList } = useDevice();
  const { videoUpload, getUploadFinished, deleteVideo } = useVideo();
  const { t } = useTranslation();
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [startTime, setStartTime] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [endTime, setEndTime] = React.useState<Dayjs | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchDeviceList = async () => {
      const deviceList = await getDeviceList();
      if (deviceList !== null) {
        setDevicess(deviceList);
      }
    }
    fetchDeviceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHeaderMenu = (menu: HeaderMenu) => {
    if (menu === 'record') {
      return;
    }
    switch (menu) {
      case 'home':
        navigate('/Home');
        break;
      case 'streaming':
        navigate('/LiveStreaming');
        break;
      case 'device':
        navigate('/DeviceList');
        break;
      case 'user':
        navigate('/UserList');
        break;
    }
  }

  const onNodeSelect = (event: React.SyntheticEvent, nodeIds: string[] | string) => {
    if (nodeIds !== '1') {
      const targetDevice = devices.find(row => row.device_id === nodeIds);
      if (targetDevice !== undefined) {
        setSelectDevice(targetDevice);
        const target = document.getElementsByTagName('video')[0];
        if (target) {
          (target as HTMLVideoElement).pause()
          target?.removeAttribute("src");
          (target as HTMLVideoElement).load()
        }
      }
    } else {
      setSelectDevice(undefined);
    }
  };

  const onClickUploadButton = async () => {
    if (startDate === null || startTime === null || endDate === null || endTime === null) {
      // 未入力
      alert(t('開始日時および終了日時を入力してください。'));
      return;
    }

    setIsLoading(true);
    const start = `${startDate.format('YYYYMMDD')}${startTime.format('HHmm')}00`;
    const end = `${endDate.format('YYYYMMDD')}${endTime.format('HHmm')}00`;
    const result = await videoUpload(selectDevice!.device_id, start, end);
    if (!result) {
      alert(t('アップロードに失敗しました。再度アップロードしてください。'));
      return;
    }

    while (!await getUploadFinished(selectDevice!.device_id)) {
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }

    const deviceList = await getDeviceList();
    if (deviceList !== null) {
      setDevicess(deviceList);
      const targetDevice = deviceList.filter(row => row.device_id === selectDevice?.device_id)[0];
      setSelectDevice(targetDevice);
    }
    setIsLoading(false);
  }

  const onClickVideoRow = async (videoId: number) => {
    if (selectDevice !== undefined) {
      const target = document.getElementById('video-' + selectDevice.device_id);
      target?.setAttribute("src", baseUrl + "/api/web/get_video?video_id=" + videoId);
    }
  }

  const onClickDownload = async (videoId: number) => {
    if (selectDevice !== undefined) {
      const link = document.createElement('a')
      link.href = baseUrl + "/api/web/download_video?video_id=" + videoId
      link.download = baseUrl + "/api/web/download_video?video_id=" + videoId
      link.target = "_blank";
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const onClickDelete = async (videoName: string, videoId: number) => {
    if (selectDevice !== undefined && window.confirm(t('ビデオ削除', { videoName: videoName }))) {
      setIsLoading(true);

      const result = await deleteVideo(videoId);
      if (result) {
        const target = document.getElementsByTagName('video')[0];
        if (target) {
          (target as HTMLVideoElement).pause()
          target?.removeAttribute("src");
          (target as HTMLVideoElement).load()
        }

        const deviceList = await getDeviceList();
        if (deviceList !== null) {
          setDevicess(deviceList);
          const targetDevice = deviceList.filter(row => row.device_id === selectDevice?.device_id)[0];
          setSelectDevice(targetDevice);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        alert(t("削除に失敗しました。再度削除してください。"));
      }
    }
  }

  const videoList = () => {
    if (selectDevice === undefined) {
      return null;
    }

    const list = [];
    for (const video of selectDevice.video_list) {
      list.push(
        <Grid item key={video.id}>
          {video.name}
          <IconButton onClick={ () => onClickVideoRow(video.id )}>
            <PlayCircleFilledIcon/>
          </IconButton>
          <IconButton onClick={ () => onClickDownload(video.id )}>
            <DownloadIcon/>
          </IconButton>
          <IconButton onClick={ () => onClickDelete(video.name, video.id )}>
            <DeleteIcon/>
          </IconButton>
        </Grid>)
    }
    return list
  }

  const displayCompnent = () => {
    if (selectDevice === undefined) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '90vh' }}>
          { t('左のメニューからデバイスを選択してください。') }
        </Box>
      );
    } else {
      return (
        <Grid container direction={'column'} alignItems={'stretch'} spacing={3} justifyContent={'stretch'}>
          <Grid item>
            <Typography
                  align='left'
                  color="inherit"
                  noWrap
                  sx={{ mb: 2 }}
              >
                {t('監視カメラから録画ファイルをアップロード')}
            </Typography>
            <Grid container direction={'row'} alignItems={'center'} spacing={2} justifyContent={'left'}>
              <Grid item><DatePicker label={t('開始日')} onChange={setStartDate} /></Grid>
              <Grid item><TimePicker label={t('開始時間')} onChange={setStartTime} /></Grid>
              <Grid item>〜</Grid>
              <Grid item><DatePicker label={t('終了日')} onChange={setEndDate} /></Grid>
              <Grid item><TimePicker label={t('終了時間')} onChange={setEndTime} /></Grid>
              <Grid item>
                <IconButton onClick={onClickUploadButton}>
                  <CloudUploadIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.3);', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh'  }}>
              <video id={'video-' + selectDevice?.device_id} style={{width: '100%', height: '100%'}} controls preload='auto'>
              </video>
            </Box>
          </Grid>
          <Grid item>
            <Grid container direction={'column'} alignItems={'flex-start'}>
              <Grid item>{t('ファイル名')}</Grid>
              {videoList()}
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar open={open} handleHeaderMenu={handleHeaderMenu} selectedHeaderMenu={'record'} />
        <Drawer open={open} menuListItem={MenuListItem(devices, onNodeSelect)} />
        <Box
            component="main"
            sx={{
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            }}
        >
          <Toolbar />
          <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            <Container maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {displayCompnent()}
            </Container>
          </Container>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
    </Box>
  );
}
