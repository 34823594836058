import { Role, SignalingClient } from "amazon-kinesis-video-streams-webrtc";
import {
    ChannelRole,
    DescribeSignalingChannelCommand,
    DescribeSignalingChannelCommandOutput,
    GetSignalingChannelEndpointCommand,
    GetSignalingChannelEndpointCommandInput,
    GetSignalingChannelEndpointCommandOutput,
    CreateSignalingChannelCommand,
    KinesisVideoClient,
    CreateSignalingChannelCommandInput,
    CreateSignalingChannelCommandOutput
} from '@aws-sdk/client-kinesis-video';
import { awsConfig } from '../../../../common/constants';
import { GetIceServerConfigCommand, GetIceServerConfigCommandOutput, KinesisVideoSignalingClient, KinesisVideoSignalingClientConfig } from "@aws-sdk/client-kinesis-video-signaling";
import { v4 as Uuid } from 'uuid';
import { PanoramaUtil } from "../../../../lib/panorama";
import { RefObject } from "react";

/** クライアント設定値 */
var kinesisVideoClient: KinesisVideoClient | undefined = undefined;

const master = {
    signalingClient: null as any,
    peerConnectionByClientId: {} as any,
    dataChannelByClientId: {} as any,
    localStream: null as any,
    remoteStreams: [] as any,
    peerConnectionStatsInterval: null as any,
};
const viewer = {} as any;

/** master設定用インターフェース  */
interface StartMasterInput {
    channelName: string;
    natTraversal: NatTraversal;
    widescreen: boolean;
    useTrickleICE: boolean;
}

/** viewer設定用インターフェース */
interface StartViewerInput {
    channelName: string;
    natTraversal: NatTraversal;
    widescreen: boolean;
    sendVideo: boolean;
    sendAudio: boolean;
    useTrickleICE: boolean;
    video: HTMLElement | null;
    fisheyeCanvas: HTMLElement | null;
    panoramaCanvas: HTMLElement | null;
    upperCanvas: HTMLElement | null;
    lowerCanvas: HTMLElement | null;
    degree: RefObject<number | undefined>;
    isFlip: RefObject<boolean>;
}

/** NAT設定 */
export type NatTraversal = 'STUN/TURN' | 'TURN' | 'DISABLED';

/** master設定 */
export const createMaster = async (accessKeyId: string, secretAccessKey: string, params: StartMasterInput): Promise<{ master: string, iceServer: string }> => {

    // クライアントインスタンスチェック
    if (kinesisVideoClient === undefined) {
        kinesisVideoClient = new KinesisVideoClient({
            region: awsConfig.region,
            credentials: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey
            }
        });
    }

    // Channel ARN取得
    const describeSignalingChannelCommand = new DescribeSignalingChannelCommand({
        ChannelName: params.channelName,
    })
    const describeSignalingChannelResponse: DescribeSignalingChannelCommandOutput = await kinesisVideoClient.send(describeSignalingChannelCommand);
    if (!describeSignalingChannelResponse.ChannelInfo!.ChannelARN) {
        throw new Error('Channelinfo.取得失敗');
    }
    const channelARN: string = describeSignalingChannelResponse.ChannelInfo!.ChannelARN;
    console.log('[MASTER] Channel ARN: ', channelARN);

    // Signaling Client エンドポイント取得
    const getSignalingChannelEndpointCommandInput: GetSignalingChannelEndpointCommandInput = {
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
            Protocols: ['WSS', 'HTTPS'],
            Role: ChannelRole.MASTER,
        },
    };
    const getSignalingChannelEndpointCommand = new GetSignalingChannelEndpointCommand(getSignalingChannelEndpointCommandInput);
    const getSignalingChannelEndpointResponse: GetSignalingChannelEndpointCommandOutput = await kinesisVideoClient.send(getSignalingChannelEndpointCommand);
    if (!getSignalingChannelEndpointResponse.ResourceEndpointList) {
        throw new Error('getSignalingChannelEndpoint.失敗');
    };

    const endpointsByProtocol: { [key: string] : string } = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
        // @ts-ignore
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints;    
    }, {'': ''});
    console.log('[MASTER] Endpoints: ', endpointsByProtocol);

    // Signaling Client作成
    master.signalingClient = new SignalingClient({
        channelARN,
        channelEndpoint: endpointsByProtocol.WSS,
        role: Role.MASTER,
        region: awsConfig.region,
        credentials: {
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
        },
        systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });

    // KVSクライアント作成
    const kinesisVideoSignalingClientConfig: KinesisVideoSignalingClientConfig = {
        region: awsConfig.region,
        credentials: {
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
        },
        endpoint: endpointsByProtocol.HTTPS,
    }
    const kinesisVideoSignalingClient = new KinesisVideoSignalingClient(kinesisVideoSignalingClientConfig);
    
    // ice server設定値取得
    const getIceServerConfigCommand = new GetIceServerConfigCommand({
        ChannelARN: channelARN,
    })
    const getIceServerConfigCommandResponse: GetIceServerConfigCommandOutput = await kinesisVideoSignalingClient.send(getIceServerConfigCommand);

    // ice server設定
    const iceServers = [];
    if (params.natTraversal  !== 'DISABLED') {
        iceServers.push({ urls: `stun:stun.kinesisvideo.${awsConfig.region}.amazonaws.com:443` });
    }
    if (params.natTraversal !== 'DISABLED' && getIceServerConfigCommandResponse.IceServerList) {
        getIceServerConfigCommandResponse.IceServerList.forEach(iceServer =>
            iceServers.push({
                urls: iceServer.Uris,
                username: iceServer.Username,
                credential: iceServer.Password,
            }),
        );
    }
    console.log('[MASTER] ICE servers: ', iceServers);

    return { master: JSON.stringify(master), iceServer: JSON.stringify(iceServers) }
}

/** viewer開始 */
export const startViewer = async (accessKeyId: string, secretAccessKey: string, params: StartViewerInput) => {
    viewer.remoteView = params.video;
    viewer.fisheyeCanvasView = params.fisheyeCanvas;
    viewer.panoramaCanvasView = params.panoramaCanvas;
    viewer.upperCanvasView = params.upperCanvas;
    viewer.lowerCanvasView = params.lowerCanvas;
    viewer.degree = params.degree;
    viewer.isFlip = params.isFlip;

    const { videoToPanorama } = PanoramaUtil({ 
        video: viewer.remoteView, 
        fishEyeCanvas: viewer.fisheyeCanvasView, 
        panoramaCanvas: viewer.panoramaCanvasView, 
        upperCanvas: viewer.upperCanvasView,
        lowerCanvas: viewer.lowerCanvasView,
        degree: viewer.degree,
        isFlip: viewer.isFlip
    });

    // クライアントインスタンスチェック
    if (kinesisVideoClient === undefined) {
        kinesisVideoClient = new KinesisVideoClient({
            region: awsConfig.region,
            credentials: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey
            }
        });
    }

    // Channel ARN 取得
    const describeSignalingChannelCommand = new DescribeSignalingChannelCommand({
        ChannelName: params.channelName,
    })
    const describeSignalingChannelResponse: DescribeSignalingChannelCommandOutput = await kinesisVideoClient.send(describeSignalingChannelCommand);
    if (!describeSignalingChannelResponse.ChannelInfo!.ChannelARN) {
        throw new Error('Cannelinfo.取得失敗');
    }
    const channelARN: string = describeSignalingChannelResponse.ChannelInfo!.ChannelARN;
    console.log('[VIEWER] Channel ARN: ', channelARN);

    // Signaling Channelエンドポイント取得
    const getSignalingChannelEndpointCommandInput: GetSignalingChannelEndpointCommandInput = {
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
            Protocols: ['WSS', 'HTTPS'],
            Role: ChannelRole.VIEWER,
        },
    };
    const getSignalingChannelEndpointCommand = new GetSignalingChannelEndpointCommand(getSignalingChannelEndpointCommandInput);
    const getSignalingChannelEndpointResponse: GetSignalingChannelEndpointCommandOutput = await kinesisVideoClient.send(getSignalingChannelEndpointCommand);
    if (!getSignalingChannelEndpointResponse.ResourceEndpointList) {
        throw new Error('getSignalingChannelEndpoint.失敗');
    };

    const endpointsByProtocol: { [key: string] : string } = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
        // @ts-ignore
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints;    
    }, {'':''});
    console.log('[VIEWER] Endpoints: ', endpointsByProtocol);

    // Signaling Client作成
    viewer.signalingClient = new SignalingClient({
        channelARN,
        channelEndpoint: endpointsByProtocol.WSS,
        clientId: Uuid(),
        role: Role.VIEWER,
        region: awsConfig.region,
        credentials: {
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
        },
        systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });

    const kinesisVideoSignalingClientConfig: KinesisVideoSignalingClientConfig = {
        region: awsConfig.region,
        credentials: {
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
        },
        endpoint: endpointsByProtocol.HTTPS,
    }
    const kinesisVideoSignalingClient = new KinesisVideoSignalingClient(kinesisVideoSignalingClientConfig);

    // ice server取得
    const getIceServerConfigCommand = new GetIceServerConfigCommand({
        ChannelARN: channelARN,
    })
    const getIceServerConfigCommandResponse: GetIceServerConfigCommandOutput = await kinesisVideoSignalingClient.send(getIceServerConfigCommand);

    // ice server設定
    const iceServers = [];
    if (params.natTraversal  !== 'DISABLED') {
        iceServers.push({ urls: `stun:stun.kinesisvideo.${awsConfig.region}.amazonaws.com:443` });
    }
    if (params.natTraversal !== 'DISABLED' && getIceServerConfigCommandResponse.IceServerList) {
        getIceServerConfigCommandResponse.IceServerList.forEach(iceServer =>
            iceServers.push({
                urls: iceServer.Uris,
                username: iceServer.Username,
                credential: iceServer.Password,
            }),
        );
    }
    console.log('[VIEWER] ICE servers: ', iceServers);

    // 表示解像度設定
    const resolution = params.widescreen ? { width: { ideal: 1280 }, height: { ideal: 720 } } : { width: { ideal: 640 }, height: { ideal: 480 } };
    const constraints = {
        video: params.sendVideo ? resolution : false,
        audio: params.sendAudio,
    };

    const configuration: RTCConfiguration = {
        iceServers,
        iceTransportPolicy: params.natTraversal === 'TURN' ? 'relay' : 'all',
    };
    viewer.peerConnection = new RTCPeerConnection(configuration) as EventTarget;

    // 接続ポーリング
    viewer.peerConnectionStatsInterval = setInterval(() => viewer.peerConnection.getStats().then(), 1000);

    // open時発火
    viewer.signalingClient.on('open', async () => {
        console.log('[VIEWER] Connected to signaling service');

        if (params.sendVideo || params.sendAudio) {
            try {
                viewer.localStream = await navigator.mediaDevices.getUserMedia(constraints);
                viewer.localStream.getTracks().forEach((track: MediaStreamTrack) => viewer.peerConnection.addTrack(track, viewer.localStream));
            } catch (e) {
                console.error('[VIEWER] Could not find webcam');
                return;
            }
        }

        // SDP Offer作成
        console.log('[VIEWER] Creating SDP offer');
        await viewer.peerConnection.setLocalDescription(
            await viewer.peerConnection.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
            }),
        );

        // SDP Offer送信
        if (params.useTrickleICE) {
            console.log('[VIEWER] Sending SDP offer');
            viewer.signalingClient.sendSdpOffer(viewer.peerConnection.localDescription);
        }
        console.log('[VIEWER] Generating ICE candidates');
    });

    // SDPレスポンス受信時発火
    viewer.signalingClient.on('sdpAnswer', async (answer: RTCSessionDescriptionInit) => {
        console.log('[VIEWER] Received SDP answer');
        // Peer Connectionにanswer追加
        await viewer.peerConnection.setRemoteDescription(answer);
    });

    // ice candidate時発火
    viewer.signalingClient.on('iceCandidate', (candidate: string) => {
        console.log('[VIEWER] Received ICE candidate');
        // Peer Connectionにice candidate追加
        viewer.peerConnection.addIceCandidate(candidate);
    });

    // close時発火
    viewer.signalingClient.on('close', () => {
        console.log('[VIEWER] Disconnected from signaling channel');
    });

    // エラー発生時発火
    viewer.signalingClient.on('error', (error: unknown) => {
        console.error('[VIEWER] Signaling client error: ', error);
    });

    // ice candidate送信時発火
    // @ts-ignore
    viewer.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
        if (candidate) {
            console.log('[VIEWER] Generated ICE candidate');

            // ice candidate送信
            if (params.useTrickleICE) {
                console.log('[VIEWER] Sending ICE candidate');
                viewer.signalingClient.sendIceCandidate(candidate);
            }
        } else {
            console.log('[VIEWER] All ICE candidates have been generated');

            // SDP Offer送信
            if (!params.useTrickleICE) {
                console.log('[VIEWER] Sending SDP offer');
                viewer.signalingClient.sendSdpOffer(viewer.peerConnection.localDescription);
            }
        }
    });

    // track受診時発火
    viewer.peerConnection.addEventListener('track', (event: RTCTrackEvent) => {
        console.log('[VIEWER] Received remote track');
        if (viewer.remoteView.srcObject) {
            return;
        }
        viewer.remoteStream = event.streams[0];
        viewer.remoteView.ontimeupdate = () => {
            setTimeout(() => {
                // canvas書き込み
                requestAnimationFrame(videoToPanorama);
            }, 0);
        }
        viewer.remoteView.srcObject = viewer.remoteStream;
        viewer.remoteView.play().catch((error: unknown) => console.log(error))
    });

    console.log('[VIEWER] Starting viewer connection');
    viewer.signalingClient.open();
}

/** viewer停止 */
export const stopViewer = () => {
    console.log('[VIEWER] Stopping viewer connection');
    if (viewer.signalingClient) {
        viewer.signalingClient.close();
        viewer.signalingClient = null;
    }

    if (viewer.peerConnection) {
        viewer.peerConnection.close();
        viewer.peerConnection = null;
    }

    if (viewer.localStream) {
        viewer.localStream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
        viewer.localStream = null;
    }

    if (viewer.remoteStream) {
        viewer.remoteStream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
        viewer.remoteStream = null;
    }

    if (viewer.peerConnectionStatsInterval) {
        clearInterval(viewer.peerConnectionStatsInterval);
        viewer.peerConnectionStatsInterval = null;
    }

    if (viewer.localView) {
        viewer.localView.srcObject = null;
    }

    if (viewer.remoteView) {
        viewer.remoteView.srcObject = null;
    }

    if (viewer.canvasView) {
        viewer.canvasView = null;
    }

    if (viewer.dataChannel) {
        viewer.dataChannel = null;
    }
}

/** チャンネル作成 */
export const createSignalingChannel = async (accessKeyId: string, secretAccessKey: string, channelName: string) => {
    
    // クライアントインスタンスチェック
    if (kinesisVideoClient === undefined) {
        kinesisVideoClient = new KinesisVideoClient({
            region: awsConfig.region,
            credentials: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey
            }
        });
    }

    try {
        const describeSignalingChannelCommand = new DescribeSignalingChannelCommand({
            ChannelName: channelName,
        })
        const describeSignalingChannelResponse: DescribeSignalingChannelCommandOutput = await kinesisVideoClient.send(describeSignalingChannelCommand);
        if (describeSignalingChannelResponse.ChannelInfo!.ChannelARN) {
            return describeSignalingChannelResponse.ChannelInfo!.ChannelARN;
        }
    } catch (describeError) {
        try {
            const createSignalingChannelEndpointCommandInput: CreateSignalingChannelCommandInput = {
                ChannelName: channelName,
            };
            const createSignalingChannelEndpointCommand = new CreateSignalingChannelCommand(createSignalingChannelEndpointCommandInput);
            const createSignalingChannelEndpointResponse: CreateSignalingChannelCommandOutput = await kinesisVideoClient.send(createSignalingChannelEndpointCommand);
            if (createSignalingChannelEndpointResponse.ChannelARN === undefined) {
                throw new Error('getSignalingChannelEndpoint.失敗');
            };
            return createSignalingChannelEndpointResponse.ChannelARN;
        } catch (error) {
            console.log(`error: ${JSON.stringify(error)}`)
        }
        return null;
    }
};