import { useFetchApi } from "../../../../common/hooks/useFetchApi";
import { ApiResponse } from "../../../../common/types";

/**
 * ストリーミングhooks
 * @returns 
 */
export const useStreaming = () => {
    const { fetchApiWithToken } = useFetchApi();

    // ストリーミング開始
    const startStreaming = async (deviceId: string, master: string, ice_server: string): Promise<boolean> => {
        const result = await fetchApiWithToken<ApiResponse>({
            endpoint: '/api/web/add_streaming_task',
            method: 'POST',
            body: JSON.stringify({
                device_id: deviceId,
                master: master,
                ice_server: ice_server
            })
        });

        if (result === null || result.result_code !== '200') {
            // APIエラー
            return false;
        } else {
            return true;
        }
    }

    return { startStreaming }
  };