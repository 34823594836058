import Grid from "@mui/material/Grid"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Button, Container, Pagination, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { createSignalingChannel, createMaster, startViewer } from "../presenter";
import { Device } from "../../../../common/types";
import { useTranslation } from "react-i18next";
import { useStreaming } from "../hooks/useStreaming";
import { useAtom } from "jotai";
import { accessKeyIdAtom, secretAccessKeyAtom } from "../../../../store/atoms/aws";

/** 1ページ表示件数 */
const countPerPage = 4;

export const MultiVideo = ({ devices }: { devices: Device[] }) => {
    const [page, setPage] = useState(1);
    const handlePagination = (event: React.ChangeEvent<unknown>, page: number) => {
        setPage(page);
    };
    const { startStreaming } = useStreaming();
    const [deviceRunning, setDeviceRunning] = useState(devices.flatMap(row => row.is_running ? row.device_id : undefined))
    const degree = useRef<number | undefined>(undefined);
    const isFlip = useRef<boolean>(false);
    const { t } = useTranslation();
    const [accessKeyId, ] = useAtom(accessKeyIdAtom);
    const [secretAccessKey, ] = useAtom(secretAccessKeyAtom);

    const totalPage = Math.floor(Object.keys(devices).length / countPerPage) + 1;

    const handleClickStartStreaming = async (target: Device) => {
        var result = false
        if (!deviceRunning.includes(target.device_id)) {
            // チャンネル確認/作成
            await createSignalingChannel(accessKeyId, secretAccessKey, target.device_id);

            // master作成
            const { master, iceServer } = await createMaster(accessKeyId, secretAccessKey, {
                channelName: target.device_id,
                natTraversal: 'STUN/TURN',
                widescreen: false,
                useTrickleICE: true,
            });

            result = await startStreaming(target.device_id, master, iceServer);
        } else {
            result = true;
        }
        if (result) {
            setDeviceRunning([...deviceRunning, target.device_id]);
            setTimeout(async function() {
                // viewer接続
                await startViewer(accessKeyId, secretAccessKey, {
                    channelName: target.device_id,
                    natTraversal: 'STUN/TURN',
                    widescreen: false,
                    sendVideo: false,
                    sendAudio: true,
                    useTrickleICE: true,
                    video: document.getElementById('video-' + target.device_id),
                    fisheyeCanvas: null,
                    panoramaCanvas: null,
                    upperCanvas: null,
                    lowerCanvas: null,
                    degree: degree,
                    isFlip: isFlip
                })
                
            }, 3000);
        } else {
            // エラー
            alert(t('ストリーミング開始処理に失敗しました。再度ストリーミング開始してください。'));
        }
    }

    // グリッド個々
    const gridItem = (position: number) => {
        const device = devices[countPerPage * (page - 1) + position]
        if (device === undefined) {
            return <Grid item sx={{ width: '50%', height: '43vh' }} />;
        } else {
            return (
                <Grid item xs={6}>
                    <Container sx={{ my: 1 }}>
                        <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.3);', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh'  }}>
                            {
                                deviceRunning.includes(device.device_id) ?
                                    <video id={'video-' + device.device_id} style={{width: '100%', height: '100%' }} controls></video>   
                                :
                                    <Button variant="contained" startIcon={<PlayArrowIcon />} onClick={() => handleClickStartStreaming(device)}>
                                        {t('ストリーミング開始')}
                                    </Button>
                            }
                        </Box>
                        <Typography
                            align='right'
                            color="inherit"
                            noWrap
                        >
                            {device.name}
                        </Typography>
                    </Container>
                </Grid>
            );
        }
    }

    return (
        <Container maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Grid container alignItems={'stretch'}>
                {gridItem(0)}
                {gridItem(1)}
                {gridItem(2)}
                {gridItem(3)}
            </Grid>
            <Pagination count={totalPage} shape="rounded" sx={{ mt: 2 }} onChange={handlePagination} />
        </Container>
    );
}