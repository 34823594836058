import { Box, Button, Divider, List, Toolbar, Typography } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { useTranslation } from "react-i18next";
import { HeaderMenu } from "../../common/types";

/** ドロワーサイズ  */
const drawerWidth: number = 300;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

/** ヘッダ */
const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

/** ドロワー */
const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export const AppBar = ({ open, handleHeaderMenu, selectedHeaderMenu }: {open: boolean, handleHeaderMenu: (menu: HeaderMenu) => void, selectedHeaderMenu: HeaderMenu}) => {
  const { t } = useTranslation();
  const headerMenu: HeaderMenu[] = [
    "home",
    "streaming",
    "record",
    "device",
    "user"
  ];

  const getHeaderMenuString = (menu: HeaderMenu) => {
    switch (menu) {
      case 'home':
        return t('ホーム');
      case 'streaming':
        return t('ライブ映像');
      case 'record':
        return t('録画再生');
      case 'device':
        return t('デバイス管理');
      case 'user':
        return t('ユーザ管理');
      default:
        return null;
    }
  }

  return (
    <StyledAppBar position="absolute" open={open} color={'default'} elevation={0} sx={{ bgcolor: 'white', borderBottom: '1px solid #ddd' }}>
        <Toolbar>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {headerMenu.map((page) => (
            <Button
                className={page === selectedHeaderMenu ? "selected" : ""}
                key={page}
                onClick={() => handleHeaderMenu(page) }
                sx={{ mx: 3, my: 2, color: 'black', display: 'block' }}
            >
                {getHeaderMenuString(page)}
            </Button>
            ))}
        </Box>
        </Toolbar>
    </StyledAppBar>
  )
}

export const Drawer = ({ open, menuListItem }: { open: boolean, menuListItem: JSX.Element | undefined }) => {
  const { t } = useTranslation();
  return (
    <StyledDrawer variant="permanent" open={open}>
      <Toolbar
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: [1],
        }}
      >
          <Typography
            align='left'
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
          >
              {t('Security Camera クラウド')}
          </Typography>
      </Toolbar>
      <Divider />
      <List component="nav">
          {menuListItem}
      </List>
    </StyledDrawer>
  );
}