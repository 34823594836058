import { useAtom } from "jotai";
import { useAuthMutators } from "../../../../common/hooks/useAuth";
import { useFetchApi } from "../../../../common/hooks/useFetchApi";
import { LoginApiResponse } from "../../../../common/types";
import { authRefreshTokenAtom, authTokenAtom, authTokenTypeAtom } from "../../../../store/atoms/auth";
import { accessKeyIdAtom, secretAccessKeyAtom } from "../../../../store/atoms/aws";

/**
 * サインインhooks
 * @returns 
 */
export const useSignIn = () => {
    const { fetchApi } = useFetchApi();
    const { login } = useAuthMutators();
    const [, setAuthToken] = useAtom(authTokenAtom);
    const [, setAuthRefrechToken] = useAtom(authRefreshTokenAtom);
    const [, setAuthTokenType] = useAtom(authTokenTypeAtom);
    const [, setAccessKeyId] = useAtom(accessKeyIdAtom);
    const [, setSecretAccessKey] = useAtom(secretAccessKeyAtom);

    // 認証
    const authenticate = async (loginId: string, password: string): Promise<boolean> => {
        const result = await fetchApi<LoginApiResponse>({
            endpoint: '/api/web/login',
            method: 'POST',
            body: JSON.stringify({ login_id: loginId, password })
        })

        if (result == null || result.result_code !== '200') {
            // ログインエラー
            return false;
        } else {
            login();
            setAuthToken(result.access_token);
            setAuthRefrechToken(result.refresh_token);
            setAuthTokenType(result.token_type);
            setAccessKeyId(result.aws_access_key_id);
            setSecretAccessKey(result.aws_secret_access_key);
            return true;
        }
    }

    return authenticate
  };